// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogg-js": () => import("./../../../src/pages/blogg.js" /* webpackChunkName: "component---src-pages-blogg-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kommunikation-js": () => import("./../../../src/pages/kommunikation.js" /* webpackChunkName: "component---src-pages-kommunikation-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-creandia-js": () => import("./../../../src/pages/om-creandia.js" /* webpackChunkName: "component---src-pages-om-creandia-js" */),
  "component---src-pages-uppdrag-js": () => import("./../../../src/pages/uppdrag.js" /* webpackChunkName: "component---src-pages-uppdrag-js" */),
  "component---src-templates-nyheter-template-js": () => import("./../../../src/templates/NyheterTemplate.js" /* webpackChunkName: "component---src-templates-nyheter-template-js" */),
  "component---src-templates-uppdrag-template-js": () => import("./../../../src/templates/UppdragTemplate.js" /* webpackChunkName: "component---src-templates-uppdrag-template-js" */),
  "component---src-templates-uppdragsgivare-template-js": () => import("./../../../src/templates/UppdragsgivareTemplate.js" /* webpackChunkName: "component---src-templates-uppdragsgivare-template-js" */)
}

